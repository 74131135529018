.sc-table-select-container {
  margin-bottom: 15px;
  //display: grid;
  //grid-template-columns: 2fr 3fr;
  //align-items: center;
  //grid-gap: 5;
  .sc-table-radio-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    label {
      font-size: 1rem;
      color: #555;
      .selected {
        color: #222;
        font-weight: 500;
      }
      padding: 0 20px;
      input {
        margin: 0 10px;
      }
    }
  }
}

.sleepcoach-patient-table {
  max-height: 55vh;
  @media only screen and (max-height: 780px) {
    height: 100%;
    max-height: 100%;
  }
  border: none;
  border-top: 1px solid #ccc;
}

//autodialer filters
.autodialer-filters-container {
  display: grid;
  grid-template-columns: 1fr;
  margin: 50px;
  align-items: center;
}
.autodialer-filters-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 50px;
  margin: 20px;
  grid-column-gap: 30px;
  column-gap: 30px;
  border-style: solid;
  padding: 30px;
  border-width: 1px;
  border-color: #cccccc;
}

.autodialer-filters-title {
  font-size: 5em;
  color: #0285c3;
  text-align: center;
}

.autodialer-filters-input-top-label {
  font-size: 1.2em;
  color: #78c3e7;
  font-weight: 600;
}
.autodialer-filters-row-group {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}
.autodialer-filters-row-bottom {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.autodialer-filters-input-side-label {
  font-size: 1.2em;
  color: #78c3e7;
  font-weight: 600;
}

.autodialer-filters-start {
  width: 10%;
  margin: 20px;
  background-color: #108cc6;
  color: #fff;
  padding: 10px;
  border-radius: 50px;
  text-align: center;
  font-size: 3em;
  box-shadow: 0 0 11px 2px #868686;
  justify-self: center;
  cursor: pointer;
  &:hover {
    background-color: #0c709e;
  }
}

.autodialer-filters-start-fetching {
  width: 10%;
  margin: 20px;
  background-color: rgb(75, 75, 75);
  color: #fff;
  padding: 10px;
  border-radius: 50px;
  text-align: center;
  font-size: 3em;
  box-shadow: 0 0 11px 2px #868686;
  justify-self: center;
  cursor: not-allowed;
}

.autodialer-filters-toggle-right {
  margin-right: 7px;
  justify-self: right;
  align-self: center;
  transform: scale(1.5);
}
